import React from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import HTTPCookieTests from '../../components/http-cookie-tests';
import JSCookieTests from '../../components/js-cookie-test';
import { getRandomString } from '../../utils/utils';


// cookies that will be set with the Set-Cookie headers, 1st and 3rd parties
const tests = [
  { name: 'session-cookie', cookie: getRandomString(), query: '' },
  { name: 'cookie1', cookie: getRandomString(), query: 'exp_attr=expires&exp_sec=30' },
  { name: 'cookie2', cookie: getRandomString(), query: 'exp_attr=max-age&exp_sec=20' },
];
// cookies that will be set with js api in a 3rd party iframe
const iframeTests = [
  { name: 'iframe-session-cookie', cookie: getRandomString(), props: { api: 'document.cookie' } },
  { name: 'iframe-cookie1', cookie: getRandomString(), props: { api: 'document.cookie', exp_attr: 'expires', exp_sec: 40 } },
  { name: 'iframe-cookie2', cookie: getRandomString(), props: { api: 'document.cookie', exp_attr: 'max-age', exp_sec: 40 } },
  { name: 'iframe-cookie3', cookie: getRandomString(), props: { api: 'CookieStore', exp_sec: 40 } },
];

const CookieRemovalPage = () => {

  return (
    <Layout>
      <h1>Cookies removal</h1>
      <p>All 3rd party cookies should be removed.</p>

      <button
        className="bg-emerald-600 text-white w-full mb-10 mt-5"
        onClick={() => {
          window.location.reload();
        }}
      >
        Run test
      </button>

      <h3>1st party cookies</h3>
      <HTTPCookieTests host={process.env.GATSBY_MAIN_HOST} tests={tests} check={(cookies, tests) => {
        return tests.map((t) => (cookies[t.cookie] !== undefined));
      }} />

      <h3>3rd party cookies</h3>
      <HTTPCookieTests host={process.env.GATSBY_SECONDARY_HOST} tests={tests} check={(cookies, tests) => {
        return tests.map((t) => (cookies[t.cookie] === undefined));
      }} />

      <h3>1st party JS cookies</h3>
      <JSCookieTests host={process.env.GATSBY_MAIN_HOST} tests={iframeTests} check={(cookies, tests) => {
        return tests.map((t) => (cookies[t.cookie] !== undefined));
      }} />

      <h3>3rd party JS cookies</h3>
      <JSCookieTests host={process.env.GATSBY_SECONDARY_HOST} tests={iframeTests} check={(cookies, tests) => {
        return tests.map((t) => (cookies[t.cookie] === undefined));
      }} />

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="Removed headers" />
export default CookieRemovalPage;
